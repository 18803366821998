import { ArrowDownload } from '@styled-icons/fluentui-system-filled/ArrowDownload';
import {
  ChatBubblesQuestion,
  Headset,
  Keyboard,
  Settings,
  SignOut,
} from '@styled-icons/fluentui-system-regular';
import clsn from 'classnames';
import React, { useState } from 'react';
import useReactRouter from 'use-react-router';

import {
  useDownloadDesktopLink,
  useGetUpdateState,
  useLinkHandler,
  useRuntimeEnv,
  useSignOut,
  useTranslations,
  useUserProfile,
} from '../../hooks';
import {
  Badge,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MoreVertIcon,
  Paper,
} from '../../material';
import { Actions, useDispatch } from '../../store';
import { UserProfileAvatar } from '../UserProfileAvatar';
import { StyledMenu } from './StyledMenu';
import { useStyles } from './styles';
import { UpdateButton } from './UpdateButton';

export const NavBarMenu: React.FC<NavBarMenuProps> = ({ openSpotlightShortcutConfigModal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data: me } = useUserProfile();
  const { t } = useTranslations();

  const { inElectron } = useRuntimeEnv();

  const { history } = useReactRouter();

  const { data: downloadAppDesktopUrl } = useDownloadDesktopLink();

  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { mutate: signOut } = useSignOut();

  const openIntercom = (): void => {
    dispatch(Actions.openSupport());
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const goToFAQ = useLinkHandler('http://help.outmind.ai');

  const goToAdminConsole = (): void => {
    history.push('/admin-console');
  };

  const downloadAppDesktop = useLinkHandler(downloadAppDesktopUrl);

  const { data: updateState } = useGetUpdateState();

  return (
    <div>
      <div
        className={clsn({ [classes.avatarContainer]: true, [classes.hovered]: isHovered })}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Badge
          aria-hidden="true"
          className={classes.userProfileAvatarContainer}
          color="primary"
          invisible={!updateState?.updateAvailable}
          onClick={handleClick}
          onKeyDown={() => handleClick}
          overlap="circle"
          variant="dot"
        >
          <UserProfileAvatar user={me} />
        </Badge>
        <IconButton
          aria-controls="long-menu"
          aria-haspopup="true"
          aria-label="more"
          classes={{ root: classes.optionButton }}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      </div>
      {anchorEl ? (
        <StyledMenu
          anchorEl={anchorEl}
          id="customized-menu"
          keepMounted
          onClose={handleClose}
          open={Boolean(anchorEl)}
        >
          {inElectron ? (
            <Paper
              className={classes.styleLink}
              color="inherit"
              elevation={0}
              onClick={openSpotlightShortcutConfigModal}
            >
              <MenuItem className={classes.styledMenuItemRoot}>
                <ListItemIcon>
                  <Keyboard size="20" />
                </ListItemIcon>
                <ListItemText
                  className={classes.itemText}
                  disableTypography
                  primary={t('keyboard_shortcut')}
                />
              </MenuItem>
            </Paper>
          ) : (
            <Paper
              className={classes.styleLink}
              color="inherit"
              elevation={0}
              onClick={downloadAppDesktop}
            >
              <MenuItem className={classes.styledMenuItemRoot}>
                <ListItemIcon>
                  <ArrowDownload size="20" />
                </ListItemIcon>
                <ListItemText
                  className={classes.itemText}
                  disableTypography
                  primary={t('download_desktop')}
                />
              </MenuItem>
            </Paper>
          )}
          {me?.adminConsoleUrl ? (
            <MenuItem className={classes.styledMenuItemRoot} onClick={goToAdminConsole}>
              <ListItemIcon>
                <Settings size="20" />
              </ListItemIcon>
              <ListItemText
                className={classes.itemText}
                disableTypography
                primary={t('admin_setting')}
              />
            </MenuItem>
          ) : null}
          <MenuItem className={classes.styledMenuItemRoot} onClick={goToFAQ}>
            <ListItemIcon>
              <ChatBubblesQuestion size="20" />
            </ListItemIcon>
            <ListItemText className={classes.itemText} disableTypography primary={t('see_faq')} />
          </MenuItem>
          <MenuItem className={classes.styledMenuItemRoot} onClick={openIntercom}>
            <ListItemIcon>
              <Headset size="20" />
            </ListItemIcon>
            <ListItemText
              className={classes.itemText}
              disableTypography
              primary={t('contact_support')}
            />
          </MenuItem>
          <hr className={classes.seperatorElement} />
          <UpdateButton />
          <MenuItem className={classes.styledMenuItemRoot} onClick={signOut}>
            <ListItemIcon>
              <SignOut size="20" />
            </ListItemIcon>
            <ListItemText className={classes.itemText} disableTypography primary={t('signout')} />
          </MenuItem>
        </StyledMenu>
      ) : null}
    </div>
  );
};

/**
 * AvatarMenu shows user Avatar and the menu associated.
 */
interface NavBarMenuProps {
  openSpotlightShortcutConfigModal: () => void;
}
